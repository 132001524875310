import React from 'react';
import { Form, Container, Row, Col, Card } from 'react-bootstrap';
import { Button } from '@components/util/buttons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import logo from '@assets/images/logo.png';
import { UserService } from '@services/user.service';
import PasswordRequirementsAlert from '@components/shared/PasswordRequirementsAlert';
import ErrorHandler from '@components/util/ErrorHandler';
import { handleError } from '@store/features/alertSlice';

export default function ResetPasswordPage() {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { resetPasswordToken } = useParams();

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    UserService.updatePassword({password, password_confirmation: passwordConfirmation, reset_password_token: resetPasswordToken})
      .then((response) => {
        setLoading(false);
        history.push('/login');
      })
      .catch((error) => {
        dispatch(handleError(error));
        setLoading(false);
      });
  };

  return (
    <ErrorHandler alertsContainerId="alerts-container">
      <Container>
        <Row className="justify-content-center">
          <img src={logo} alt="Logo" style={{ width: 300 }} className="m-5" />
        </Row>
        <Row className="justify-content-center">
          <Col xs={6}>
            <Card>
              <Card.Header className="bg-gradient-primary text-white">
                Reset Password
              </Card.Header>
              <Card.Body>
                <PasswordRequirementsAlert />
                <div id="alerts-container"></div>
                <Form onSubmit={onFormSubmit}>
                  <Form.Group className="pb-3 pt-3">
                    <Form.Control
                      size="lg"
                      type="password"
                      placeholder="Type your new password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="pb-3 pt-3">
                    <Form.Control
                      size="lg"
                      type="password"
                      placeholder="Type your new password again"
                      value={passwordConfirmation}
                      isInvalid={passwordConfirmation ? password !== passwordConfirmation : false}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Your passwords do not match!</Form.Control.Feedback>
                  </Form.Group>


                  <Button variant="primary" size="lg" type="submit"
                    disabled={!password || !passwordConfirmation || password !== passwordConfirmation} loading={loading} icon='save' block>
                    Save
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ErrorHandler>
  );
}
