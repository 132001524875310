import React, { useEffect, useState } from 'react';

import { Table as RBTable } from 'react-bootstrap';
import { AddButton, DeleteButton, EditButton } from '@components/util/buttons';
import TextFilter from './TextFilter';
import { useTranslation } from 'react-i18next';

/**
 * TODO: REFACTOR
 * 
 * @param Array headers
 *  - E.g. [
 *      { text: 'Header 1' sortable: true },
 *      { text: 'Header 2' } // this one is not sortable
 *    ]
 * @param Array rows
 *  - E.g. { columns: [1123, 'Rado' ]}
 * @param Boolean showControls default = true
 *  - Whether to show edit / delete buttons per row.
 * @param Function onEdit
 * @param Function onDelete
 * @param Function onSort (headerIndex, sortOrder) => { ... }
 *
 * Renders a Table.
 */
export default function Table({
  headers,
  rows,
  showControls = true,
  onEdit,
  onDelete,
  onSort,
  onAddRow = null,
  rowHorizontalPadding, // 1|2|3|4|5
  disableDelete,
  isImmutableUnderReview = false,
  opts = {
    bordered: null,
    striped: null,
    responsive: true,
    hover: true,
    headerSticky: false,
    headersClass: null,
  }
}) {
  const { t } = useTranslation();
  const mapHeaders = headers => [...headers].map(h => ({...h, order: h.order || null }))
  const [tableRows, setTableRows] = useState([...rows]);
  const [tableHeaders, setTableHeaders] = useState(mapHeaders(headers));

  if (tableHeaders.some(h => h.sortable)) {
    if (tableRows.some(r => !r.key)) {
      throw new Error('If the table has sortable headers, its rows must have a key field and this key should be a unique value based on the rendered data.')
    }
  }

  // Sorting is related to filtering
  const filterRowsByText = (text, columnIndex) => {
    tableHeaders.forEach(h => h.order = null)
    tableHeaders[columnIndex]._filterText = text
    setTableHeaders([
      ...tableHeaders
    ])

    const nonFilterableRows = rows.filter(r => r.opts?.filterableByText === false)
    let filterableRows = rows.filter(r => r.opts?.filterableByText !== false)
    let filteredRows = filterableRows
    for (const [index, header] of tableHeaders.entries()) {
      if (header.filterableByText) {
        filteredRows = filteredRows.filter(r => {
          const filterText = (header._filterText || '').toLowerCase()
          const defaultvalueFnForSortAndFilter = (row) => row.columns[index]
          const valueFnForSortAndFilter = header.valueFnForSortAndFilter || defaultvalueFnForSortAndFilter
          const columnText = valueFnForSortAndFilter(r).toLowerCase()

          return columnText.includes(filterText)
        })
      }
    }

    filteredRows.push(...nonFilterableRows)
    setTableRows(filteredRows)
  }

  const defaultOnSort = (columnIndex, direction) => {
    const defaultvalueFnForSortAndFilter = (row) => row.columns[columnIndex]
    const valueFnForSortAndFilter = tableHeaders[columnIndex].valueFnForSortAndFilter || defaultvalueFnForSortAndFilter

    const nonSortableRows = tableRows.filter(r => r.opts?.sort === false)
    const sortableRows = tableRows.filter(r => r.opts?.sort !== false)
    const sortedRows = [...sortableRows].sort((a, b) => {
      const [valueA, valueB] = [valueFnForSortAndFilter(a), valueFnForSortAndFilter(b)]

      if (valueA > valueB) {
        return direction === 'asc' ? 1 : -1
      }

      if (direction === 'asc') {
        return valueA === valueB ? 0 : -1
      } else {
        return valueA === valueB ? 0 : -1
      }
    })

    // For now those rows are only below the sortable ones.
    // In the future we can add additional functionality here if needed.
    sortedRows.push(...nonSortableRows)

    setTableRows(sortedRows)
  }

  const columnSortFunction = onSort ? onSort : defaultOnSort

  const optsDefaults = {
    bordered: null,
    striped: null,
    responsive: true,
    hover: true,
    headerSticky: false
  };

  const optsMerged = {
    ...optsDefaults,
    ...opts
  };

  const headersClass = optsMerged.headersClass ?
    optsMerged.headersClass
    :
    'text-primary app-fs-table-header ' + (optsMerged.headerSticky ? 'sticky' : '')

  const tableOpts = {...optsMerged}
  delete tableOpts.headerClass // removes errors for unexpected attribute names
  delete tableOpts.headersClass // removes errors for unexpected attribute names
  delete tableOpts.headerSticky // removes errors for unexpected attribute names
  delete tableOpts.headerStyle // removes errors for unexpected attribute names

  useEffect(() => {
    setTableRows([...rows]);
    setTableHeaders(mapHeaders(headers));
  }, [rows, headers]);

  const setTableHeadersSortOrder = (index) => {
    tableHeaders[index].order = tableHeaders[index].order === 'asc' ? 'desc' : 'asc';
    tableHeaders.forEach((h, i) => {
      if (i !== index) {
        h.order = null
      }
    });

    setTableHeaders([...tableHeaders]);

    return tableHeaders[index].order;
  };

  const renderSortableHeaderIcon = header => {
    if (!header.order) {
      return <span className="material-icons">sort</span>
    }

    return header.order === 'asc' ?
      <span className="material-icons">arrow_drop_up</span> :
      <span className="material-icons">arrow_drop_down</span>
  }

  const renderHeader = (header, index) => {
    const headerClassName = (optsMerged.bordered ? '' : 'border-0') +  opts.headerClass ? opts.headerClass : ' font-weight-normal';

    header.attributes ||= {}
    const finalHeaderStyle = {
      ...{position: 'relative', ...optsMerged.headerStyle, verticalAlign: 'text-top', width: header.width, ...header.styleAttributes }, // default,
      ...header.attributes.style
    }

    header.attributes.style = finalHeaderStyle

    return header.sortable ? 
      <th
        key={index}
        onClick={() => {
          setTableHeadersSortOrder(index);
          if (typeof columnSortFunction === 'function') {
            columnSortFunction(index, tableHeaders[index].order, header.text);
          }
        }} 
        className={headerClassName}
        {...header.attributes}
      >
        <div className='cursor-pointer' style={{ marginBottom: '35px' }}>
          {header.text} {renderSortableHeaderIcon(header)}
        </div>
        { header.filterableByText && <TextFilter style={{ position: 'absolute', bottom: 0 }} onFilter={text => filterRowsByText(text, index)} /> }
      </th>
      :
      <th
        key={index} 
        className={headerClassName}
        {...header.attributes}
      >
        {header.text}
        { header.filterableByText && 
          <TextFilter style={{ position: 'absolute', bottom: 0 }} onFilter={text => filterRowsByText(text, index)}/>
        }
      </th>;
  }  

  return (
    <RBTable {...tableOpts}>
      <thead>
        <tr className={headersClass}>
          { tableHeaders.map((header, i) => renderHeader(header, i)) }
        </tr>
      </thead>
      <tbody>
        { tableRows.map((row, index) =>
          <tr key={row.key || `row-${index}`} className={row.className}> 
            { row.columns.map((colText, i) => {
                let tdClass = rowHorizontalPadding && i === 0 ? `pl-${rowHorizontalPadding}` : ''
                  tdClass = (rowHorizontalPadding && !showControls && i === row.columns.length - 1) ? `pr-${rowHorizontalPadding}` : tdClass
                return <td className={tdClass} key={`col-${i}`}>{ colText }</td>
              })
            }
            { showControls &&
              <td style={{ minWidth: '90px' }} className={`text-right ${rowHorizontalPadding ? `pr-${rowHorizontalPadding}` : ''}`}>
                { row.controls &&  <div>{row.controls}</div> }
                { !row.controls && onEdit && row.showEdit !== false &&
                  <EditButton isImmutableUnderReview={isImmutableUnderReview} onClick={() => onEdit(index, row)}/>
                }
                { !row.controls && onDelete && row.showDelete !== false &&
                  <DeleteButton isImmutableUnderReview={isImmutableUnderReview} onClick={() => onDelete(index, row)} disabled={disableDelete}/>
                }
              </td>
            }
          </tr>
        )}
        { onAddRow &&
          <tr className="row-add_row">
            <td colSpan={`${tableRows[0]?.columns?.length}`} style={{ textAlign: 'left', background: 'transparent' }}>
              <AddButton variant="link" isImmutableUnderReview={isImmutableUnderReview} onClick={onAddRow} text={t('add_row')} />
            </td>
          </tr>
        }
      </tbody>
    </RBTable>
  );
}