import React from 'react';
import { Toast } from 'react-bootstrap';
/**
 * alerts can contain a message and an id [{ message, id }]
 * @returns
 */
export default function AlertList({ alertClassName = '', alerts, onRemoveAlert }) {

  return alerts.map((a, i) => {
    return <Toast
      style={{position: 'relative'}}
      key={a.id}
      className={`bg-warning text-white ${alertClassName}`}
      show={true}
      onClose={() => onRemoveAlert(a.id)}
    >
      <Toast.Header
        style={{ justifyContent: 'space-between' }}
      >
        <div>
          <span className="material-icons">warning</span> <span className='ml-3'>{a.message}</span>
        </div>
      </Toast.Header>
    </Toast>;
  });
};
