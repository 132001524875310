import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import SidebarPortal from '@components/util/SidebarPortal';

const MasterSidebar = ({ accountType, currentPage }) => {
  const user = useSelector((state) => state.auth.user)

  const menuItems = useMemo(() => {
    let links = [];

    links = links.map(link => {
      if (link.link === currentPage) {
        return { ...link, highlighted: true }
      }

      if (link.subMenu) {
        link = {
          ...link,
          subMenu: link.subMenu.map(subLink => subLink.link === currentPage ? { ...subLink, highlighted: true } : subLink)
        }
      }

      return link;
    })

    return links;
  }, [accountType, currentPage, user])

  return (
    <SidebarPortal menuItems={menuItems} />
  )
}

export default MasterSidebar;
