import React from 'react';

/**
 * @param String name
 * @param String description
 * @param Boolean white text style
 * @
 *
 * User name with avatar and role description
 *
 */
export const Persona = ({ name, description, whiteText }) => {
  const initials = name?.split(/\s+/).map((w, i) => w.substring(0,1).toUpperCase()).join('');
  
  return (
    <div className='persona-container'>
      <span className={`persona-image ${whiteText ? 'persona-image-white' : ''}`}>{initials}</span>
      <div className={`persona-name-block`}>
        <div className={`persona-name ${whiteText ? 'persona-text-white' : ''}`}>{name}</div>
        <div className={`persona-description ${whiteText ? 'persona-text-white' : ''}`}>{description}</div>
      </div>
    </div>
  )
}
