import React, { useEffect } from 'react'
import { Container, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as QueryString from 'query-string';
import { GoogleService } from '@services/google.service';
import { login } from '@store/features/authSlice';

const GoogleCallbackPage = ({ accountType }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = JSON.parse(QueryString.parse(window.location.search).state).authToken;
  const googleToken = QueryString.parse(window.location.search).code;

  useEffect(async () => {
    dispatch(login({ data: token }));
    await GoogleService.setConnection(googleToken, token);
    history.replace('/user');
  }, []);

  return (
    <Container fluid className="d-flex vh-100">
      <div className="m-auto text-center">
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" role="status" />
          <span className="ml-3">
            Fetching your latest messages, please wait…
          </span>
        </div>
      </div>
    </Container>
  );
};
export default GoogleCallbackPage;
