import { useCallback, useState, useEffect } from 'react';
import fetch from './Fetcher';
import { request } from './request';
import { useErrorHandling } from '@hooks/useErrorHandling';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const UserService = {
  useFetchUsersForSelect: (type = '') => {
    const [users, setUsers] = useState([]);

    const fetchUsers = useErrorHandling(useCallback(async () => {
      const response = await UserService.all(type);
      setUsers(response.users.map(user => ({ value: user.id, label: user.name })));
    }, [type]));

    useEffect(() => {
      fetchUsers();
    }, [fetchUsers]);

    return [users, setUsers];
  },
  all: (type) => {
    return fetch(`/api/users/?type=${type}`, request.get);
  },
  get: id => {
    return fetch(`/api/users/${id}`, request.get);
  },
  logout: () => {
    return Promise.resolve();
    // return fetch('/api/users/sign_out', request.post);
  },
  delete: id => {
    return fetch(`/api/users/${id}`, request.delete);
  },
  update: user => {
    return fetch(`/api/users/${user.id}`, {
      ...request.put,
      body: JSON.stringify({ user }),
    })
  },
  create: user => {
    return fetch('/api/users/', {
      ...request.post,
      body: JSON.stringify({ user }),
    })
  },
  confirm: user => {
    return fetch('/api/users/confirm.json', {
      ...request.post,
      body: JSON.stringify({ user })
    })
  },
  getCurrent: function() {
    return fetch('/api/users/current_session.json', request.get)
  },
  generateOtpBackupCodes: function() {
    return fetch('/api/users/otp_backup_codes.json', request.post)
  },
  validateConfirmationToken: token => {
    return fetch(`/api/users/confirm.json?user[confirmation_token]=${token}`, request.get)
  },
  sendConfirmationInstructions: userId => {
    return fetch(`/api/users/send_confirmation_instructions/${userId}.json`, request.post);
  },
  updatePassword: user => {
    return fetch('/api/users/password.json', {
      ...request.put,
      body: JSON.stringify({ user }),
    })
  },
  sendPasswordResetInstructions: user => {
    return fetch('/api/users/password', {
      method: 'POST',
      body: JSON.stringify({ user }),
    })
  },
  login: (body) => {
    return fetch(`${backendUrl}/login`, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  },
  signin: (body) => {
    return fetch(`${backendUrl}/login/create`, {
      method: 'POST',
      body: JSON.stringify(body),
    })
  }
};
