import { useCallback, useState, useEffect } from 'react';
import fetch from './Fetcher';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const GoogleService = {
  getAuthUrl: (token) => {
    return fetch(`${backendUrl}/google`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },
  setConnection: (googleToken, token) => {
    return fetch(`${backendUrl}/google/oauth2callback?code=${googleToken}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  },
  revokeConnection: (token) => {
    return fetch(`${backendUrl}/google/revoke`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
  }
};
