import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Navbar from '../shared/Navbar';
import Toast from './Toast';
import ErrorHandler from './ErrorHandler';
import UnexpectedSynchronousErrorBoundary from './UnexpectedSynchronousErrorBoundary';
import fetch from '@services/Fetcher';
import { getCurrentSession } from '@store/features/authSlice';
import { completeLoading } from '@store/features/uiSlice';
import { Loading } from '@components/shared/Loading';

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const user = useSelector((state) => state.auth.user);

  const [show, setShow] = useState(false);
  const [toastVariant, setToastVariant] = useState('success');
  const [message, setMessage] = useState(false);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false)

  /**
   *
   * @param {*} m
   * @param {*} variant 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
   */
  const handleToaster = (m, variant) => {
    variant ||= 'success';
    setShow(true);
    setMessage(m);
    setToastVariant(variant);
  }

  useEffect(() => {
    if(!user)  {
      setIsLoading(true)
      fetch(`${process.env.REACT_APP_BACKEND_URL}/login/current_user`)
      .then(response => {
        dispatch(getCurrentSession(response));
      })
      .catch(error => dispatch(completeLoading(error.message)))
      .finally(() => setIsLoading(false))
    }
  }, [user]);

  if (isLoading) {
    return <Loading/>;
  }

  return (
    <Route {...rest} render={(props) => {
      if (user) {
        return (
          <>
            <ErrorHandler>
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  right: 0,
                  zIndex: 9999,
                  width: 'max-content'
                }}
              >
                <Toast show={show} message={message} variant={toastVariant} onClose={() => setShow(false)}/>
              </div>
              <Navbar basename={rest.basename} {...props} />
              <div className="wrapper" id="content-wrapper">
                <Container fluid>
                  <UnexpectedSynchronousErrorBoundary>
                    <Component {...props} handleToaster={handleToaster} />
                    <div className="text-muted text-center">
                      FOOTER TEXT
                    </div>
                  </UnexpectedSynchronousErrorBoundary>
                </Container>
              </div>
            </ErrorHandler>
          </>
        );
      }
      else {
        <Redirect to="/login" />
      }
    }} />
  )
}
