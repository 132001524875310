import { createSlice } from '@reduxjs/toolkit';

export const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    companies: []
  },
  reducers: {
    storeCompanies (state, { payload: companies }) {
      state.companies = companies;
    },
    storeCompany (state, { payload: company }) {
      const companyIndex = state.companies.findIndex(storedCompany => storedCompany.id === company.id);
      if (companyIndex >= 0) {
        state.companies.splice(companyIndex, 1, company);
      } else {
        state.companies = [...state.claimProjectReports, company];
      }
    },
  }
})

export const { storeCompanies, storeCompany } = companiesSlice.actions;
export default companiesSlice.reducer;
