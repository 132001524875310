import React from 'react';
import { Form, Container, Row, Col, Card } from 'react-bootstrap';
import { Button } from '@components/util/buttons';
import { useState } from 'react';
import { login } from '@store/features/authSlice';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import logo from '@assets/images/logo.png';
import ErrorHandler from '@components/util/ErrorHandler';
import { handleError } from '@store/features/alertSlice';
import { UserService } from '@services/user.service';
import * as QueryString from 'query-string';
import BackgroundImage from '@assets/BackgroundImage';

export default function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    UserService.login({ username, password })
      .then((response) => {
        setLoading(false);
        dispatch(login(response));
        const redirectLocation = '/';
        const next = QueryString.parse(history.location.search);
        history.replace(Object.keys(next).length === 0 ? redirectLocation : next);
      })
      .catch(function (error) {
        dispatch(handleError(error))
        setLoading(false);
      });
  };

  return (
    <ErrorHandler alertsContainerId='alerts-container' alertClassName='w-100 mw-100 mb-3'>
      <Container>
        <BackgroundImage />
        <Row className="justify-content-center">
          <img src={logo} alt="Logo" className="m-5" />
        </Row>
        <Row className="justify-content-center">
          <Col xs={6}>
            <Card>
              <Card.Body>
                <div id="alerts-container"></div>
                <Form onSubmit={onFormSubmit}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      size="lg"
                      type="email"
                      placeholder="Enter email"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Label className="float-right">
                      <Link to={'/forgot_password'}>Forgot Password?</Link>
                    </Form.Label>
                    <Form.Control
                      size="lg"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Group>

                  <Button variant="primary" size="lg" type="submit"
                    loading={loading} disabled={!username || !password} icon='chevron_right' iconPosition="right" block>
                    Come on in
                  </Button>
                  <Form.Label>
                    <Link to={'/new_user'}>Create New User</Link>
                  </Form.Label>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ErrorHandler>
  );
}
