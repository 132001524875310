import { useCallback, useState, useEffect } from 'react';
import fetch from './Fetcher';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const MessageService = {
  getAll: (token) => {
    return fetch(`${backendUrl}/message`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },

  generateResponse: (token, id) => {
    return fetch(`${backendUrl}/message/${id}/generate`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },

  sendDraft: (token, id, draftId) => {
    return fetch(`${backendUrl}/message/${id}/send?draftId=${draftId}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
};
