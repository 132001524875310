import React, { useEffect, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect, BrowserRouter as Router, useHistory } from 'react-router-dom';
import LoginPage from '@pages/LoginPage';
import NewUserPage from '@pages/NewUserPage';
import ForgotPasswordPage from '@pages/ForgotPasswordPage';
import ResetPasswordPage from '@pages/ResetPasswordPage';
import ConfirmAccountPage from '@pages/ConfirmAccountPage';
import { Loading } from '@components/shared/Loading';
import { ProtectedRoute } from '@components/util/ProtectedRoute';
import UserPage from '@pages/protected/UserPage';
import GoogleCallbackPage from '@pages/protected/GoogleCallbackPage';
import { getCurrentSession } from '@store/features/authSlice';
import { completeLoading } from '@store/features/uiSlice';

const App = () => {
  const { isLoading } = useSelector((state) => state.ui);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const basename = process.env.PUBLIC_URL;

  useEffect(() => {
    const options = {
      headers: {}
    };
    if (token) options.headers.Authorization = `Bearer ${token}`;
    // fetch('http://localhost:5000/login/current_user', options)
    //   .then(response => response.json())
    //   .then(response => {
    //     dispatch(getCurrentSession(response));
    //     dispatch(completeLoading(''));
    //   })
    //   .catch(error => dispatch(completeLoading(error.message)));
    dispatch(completeLoading(''));
  }, [token]);


  /**
   * Never use React.Fragment (<></>) or random pages will stop working.
   */
  return (
    <Router basename={basename}>
      {isLoading ? (
        <Loading />
      ) : (
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path="/">
              <Redirect to="/user" />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/new_user">
              <NewUserPage />
            </Route>
            <Route path="/forgot_password">
              <ForgotPasswordPage />
            </Route>
            <Route path="/reset_password/:resetPasswordToken">
              <ResetPasswordPage />
            </Route>
            <Route path="/confirm/:confirmAccountToken">
              <ConfirmAccountPage />
            </Route>
            <ProtectedRoute
              exact
              path="/user"
              basename={basename}
              component={UserPage}
            />
            <Route
              exact
              path="/google/oauth2callback"
              basename={basename}
              component={GoogleCallbackPage}
            />
          </Switch>
        </Suspense>
      )}
    </Router>
  );
};

export default App;
