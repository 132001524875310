import React from 'react';

import { Button } from "./Button";

export function DeleteButton({ iconStyles, iconClassName, variant = 'light', ...props }) {
  const defaultIconClassName = "text-warning rounded-circle mx-auto"
  const passedIconClassName = iconClassName ? iconClassName : defaultIconClassName

  return (
    <Button variant={variant} className="p-1 d-inline-flex rounded-circle text-warning" {...props} icon="delete" iconClassName={passedIconClassName} />
  )
};