import { Alert } from 'react-bootstrap';

export default function PasswordRequirementsAlert() {
  return (
    <Alert variant="primary">
      <span className="material-icons md-18">info</span> Your password needs to:
      <ul>
        <li>have at least 8 characters</li>
        <li>include a number</li>
        <li>include a capital letter</li>
      </ul>
    </Alert>
  );
}
