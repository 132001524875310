import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Navbar as BsNavbar, Nav, Button, Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '@store/features/authSlice';
import { handleError } from '@store/features/alertSlice';
import { UserService } from '@services/user.service';
import { Persona } from '@components/shared/Persona';
import { Loading } from '@components/shared/Loading';
import logo from '@assets/images/logo.png';
import HelpModal from './HelpModal';

export default function Navbar ({ userRole }) {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);

  const onLogOut = (e) => {
    e.preventDefault();

    UserService.logout().then(() => {
      dispatch(logout());
      history.replace('/login');
    }).catch(err => dispatch(handleError(err.description)));
  };

  if(!user) return <Loading />;

  return (
    <>
      <HelpModal
        show={modalOpen}
        onHide={() => setModalOpen(false)}
      />
      <BsNavbar sticky="top" expand="md">
        <Container className='navbar-container'>
          <BsNavbar.Brand href={`${userRole}/companies`}>
            <img src={logo} alt="Ayming Logo" height="42" />
          </BsNavbar.Brand>
          <BsNavbar.Toggle aria-controls="basic-navbar-nav" />

          <BsNavbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" id="navbar-breadcrumbs" />
            <Nav className="navbar-right">
              <Persona name={user.name} />
              <Button variant="text" onClick={onLogOut}>
                <span className="material-icons md-18">logout</span>
              </Button>
              <Button className="ml-3" variant="text" onClick={() => setModalOpen(true)}>
                <span className="material-icons md-18 blue">question_mark</span>
              </Button>
            </Nav>
          </BsNavbar.Collapse>
        </Container>
      </BsNavbar>
    </>
  );
}
