import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

export default function TextFilter({ onFilter, ...props }) {
  const [value, setValue] = useState('');

  return (
    <div { ...props }>
      <Form.Control
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value)
          onFilter(e.target.value)
        }}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
        placeholder='Filter'
      />
    </div>
  )
}
