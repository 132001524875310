import PasswordRequirementsAlert from '@components/shared/PasswordRequirementsAlert'
import { Button } from '@components/util/buttons';
import ErrorHandler from '@components/util/ErrorHandler'
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Form, Row } from 'react-bootstrap'
import logo from '@assets/images/logo.png';
import { Link, useHistory, useParams } from 'react-router-dom'
import { UserService } from '@services/user.service'
import { useDispatch } from 'react-redux'
import { handleError } from '@store/features/alertSlice'

export default function ConfirmAccountPage() {
  const [codeValidity, setCodeValidity] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const { confirmAccountToken } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    UserService.validateConfirmationToken(confirmAccountToken)
      .then(code => setCodeValidity(code.code))
      .catch((error) => {
        dispatch(handleError(error));
        setLoading(false);
      });
  }, [confirmAccountToken, dispatch]);

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    UserService.confirm({password, password_confirmation: passwordConfirmation, confirmation_token: confirmAccountToken})
      .then(() => {
        setLoading(false);
        history.push('/otp/setup');
      })
      .catch((error) => {
        dispatch(handleError(error));
        setLoading(false);
      });
  };

  const invalidTokenCard = () =>
    <Card className='p-5'>
      <Card.Body className="text-center">
        <h2><span className="material-icons">exclamation</span> Invalid Confirmation Token</h2>

        <Button as={Link} variant="link" to={'/login'} icon='chevron_left'>
          back to log in page
        </Button>
      </Card.Body>
    </Card>

  const alreadyConfirmedCard = () =>
    <Card>
      <Card.Header className="bg-gradient-primary text-white">
        <span className="material-icons">exclamation</span> Account Already Confirmed
      </Card.Header>
      <Card.Body className="text-center pt-5 pl-5 pr-5">
        <div className='mb-5'>Your account has already been confirmed. please log in instead.</div>

        <Button as={Link} variant="primary" to={'/login'}>
          Log in <span className="material-icons md-18">chevron_right</span>
        </Button>
      </Card.Body>
    </Card>

  return (
    <ErrorHandler alertsContainerId="alerts-container">
      <Container>
        <Row className="justify-content-center">
          <img src={logo} alt="Logo" style={{ width: 300 }} className="m-5" />
        </Row>
        <Row className="justify-content-center">
          <Col xs={6}>
          {codeValidity === 'invalid' && invalidTokenCard()}
          {codeValidity === 'already_confirmed' && alreadyConfirmedCard()}
          {codeValidity === 'valid' &&
            <Card>
              <Card.Header className="bg-gradient-primary text-white">
                Confirm Your Account
              </Card.Header>
              <Card.Body>
                <PasswordRequirementsAlert />
                <div id="alerts-container"></div>
                <Form onSubmit={onFormSubmit}>
                  <Form.Group className="pb-3 pt-3">
                    <Form.Control
                      size="lg"
                      type="password"
                      placeholder="Type your new password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="pb-3 pt-3">
                    <Form.Control
                      size="lg"
                      type="password"
                      placeholder="Type your new password again"
                      value={passwordConfirmation}
                      isInvalid={passwordConfirmation ? password !== passwordConfirmation : false}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                    />
                    <Form.Control.Feedback type="invalid">Your passwords do not match!</Form.Control.Feedback>
                  </Form.Group>


                  <Button
                    variant="primary"
                    size="lg"
                    type="submit"
                    disabled={!password || !passwordConfirmation || password !== passwordConfirmation}
                    loading={loading}
                    icon='chevron_right'
                    iconPosition='right'
                    block
                  >
                    Set Password and Continue
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          }
          </Col>
        </Row>
      </Container>
    </ErrorHandler>
  )
}
