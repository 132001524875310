import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isLoading: true,
    errors: '',
  },
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
      state.errors = '';
    },
    completeLoading: (state, action) => {
      state.isLoading = false;
      state.errors = action.payload;
    },
  },
});

export const { setLoading, completeLoading } = uiSlice.actions;
export default uiSlice.reducer;
