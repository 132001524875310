import React from 'react';
import { Form, Container, Row, Col, Card } from 'react-bootstrap';
import { Button } from '@components/util/buttons';
import { useState } from 'react';
import { login } from '@store/features/authSlice';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import logo from '@assets/images/logo.png';
import ErrorHandler from '@components/util/ErrorHandler';
import { handleError } from '@store/features/alertSlice';
import { UserService } from '@services/user.service';
import * as QueryString from 'query-string';
import BackgroundImage from '@assets/BackgroundImage';

export default function NewuserPage() {
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const onFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    UserService.signin({ username, firstName, lastName, password })
      .then((response) => {
        setLoading(false);
        history.push('/login');
      })
      .catch(function (error) {
        dispatch(handleError(error))
        setLoading(false);
      });
  };

  return (
    <ErrorHandler alertsContainerId='alerts-container' alertClassName='w-100 mw-100 mb-3'>
      <Container>
        <BackgroundImage />
        <Row className="justify-content-center">
          <img src={logo} alt="Logo" className="m-5" />
        </Row>
        <Row className="justify-content-center">
          <Col xs={6}>
            <Card>
              <Card.Body>
                <div id="alerts-container"></div>
                <Form onSubmit={onFormSubmit}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      size="lg"
                      type="email"
                      placeholder="Enter email"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      size="lg"
                      type="name"
                      placeholder="Enter first name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      size="lg"
                      type="name"
                      placeholder="Enter last name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      size="lg"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      type="password"
                      placeholder="Password"
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    size="lg"
                    type="submit"
                    loading={loading}
                    disabled={!username || !password || !firstName || !lastName}
                    icon="chevron_right"
                    iconPosition="right"
                    block
                  >
                    Register
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ErrorHandler>
  );
}
