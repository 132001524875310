import React from 'react';
import { Alert, Form, Container, Row, Col, Card } from 'react-bootstrap';
import { Button } from '@components/util/buttons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import logo from '@assets/images/logo.png';
import { handleError } from '@store/features/alertSlice';
import ErrorHandler from '@components/util/ErrorHandler';
import { UserService } from '@services/user.service';

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [instructionsSent, setInstructionsSent] = useState(false);
  const dispatch = useDispatch();

  const onFormSubmit = (e) => {
    e.preventDefault();
    setInstructionsSent(false);
    setLoading(true);
    UserService.sendPasswordResetInstructions({email})
      .then((response) => {
        setInstructionsSent(true);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(handleError(error));
        setLoading(false);
      });
  };

  return (
    <ErrorHandler alertsContainerId="alerts-container" alertClassName="w-100 mw-100 mb-3">
      <Container>
        <Row className="justify-content-center">
          <img src={logo} alt="Logo" style={{ width: 300 }} className="m-5" />
        </Row>
        <Row className="justify-content-center">
          <Col xs={6}>
            <Card>
              <Card.Header className="bg-gradient-primary text-white">
                Forgot Password
              </Card.Header>
              <Card.Body>
                <div id="alerts-container"></div>
                { instructionsSent ? <>
                  <Alert variant="primary" onClose={() => setInstructionsSent(false)} dismissible>
                    Successfully sent password reset instructions.
                  </Alert>
                </> : ''}
                <Form onSubmit={onFormSubmit}>
                  <Form.Group className="pb-3 pt-3">
                    <Form.Control
                      size="lg"
                      type="email"
                      placeholder="Your email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>


                  <Button variant="primary" size="lg" type="submit" disabled={!email} loading={loading} icon='mail' block>
                    Send password reset instructions
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ErrorHandler>
  );
}
