import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BreadcrumbsPortal from '@components/util/BreadcrumbsPortal';
import MasterSidebar from '@components/util/MasterSidebar';
import { Button } from '@components/util/buttons';
import { Table } from '@components/util/Table';
import { GoogleService } from '@services/google.service';
import { MessageService } from '@services/message.service';

const UserPage = ({ accountType }) => {
  const token = useSelector((state) => state.auth.token);
  const [messages, setMessages] = useState([]);
  const [rows, setRows] = useState([]);

  const setConnection = useCallback(async () => {
    const authUrl = await GoogleService.getAuthUrl(token);
    window.open(authUrl.data, '_self');
  }, []);

  const revokeConnection = useCallback(async () => {
    await GoogleService.revokeConnection(token);
  }, []);

  useEffect(async () => {
    const messages = (await MessageService.getAll(token)).data;
    setMessages(messages);
  }, []);

  const generateAIResponse = (id) => {
    MessageService.generateResponse(token, id);
  };

  const sendDraft = (id, externalId) => {
    MessageService.sendDraft(token, id, externalId);
  };

  const isDraft = (message) => message.tags.indexOf('DRAFT') !== -1;

  useEffect(() => {
    setRows(
      messages.map((message) => ({
        columns: [
          message.interlocutor.name,
          message.body.slice(0, 70) + '...',
          isDraft(message) ? (
            <Button onClick={() => sendDraft(message.id, message.externalId)}>
              Send draft
            </Button>
          ) : (
            <Button onClick={() => generateAIResponse(message.id)}>
              Generate AI response
            </Button>
          ),
        ],
      }))
    );
  }, [messages]);

  const headers = [
    { text: 'Interlocutor' },
    { text: 'Body' },
    { text: 'Actions' },
  ];

  return (
    <>
      <MasterSidebar accountType={accountType} currentPage='users' />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/admin' }}>
            {'home'}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{'users'}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <Card>
        <Card.Header>
          <Button variant="primary" onClick={setConnection}>
            Set connection to Gmail
          </Button>
          <Button variant="secondary" onClick={revokeConnection}>
            Revoke connection to Gmail
          </Button>
        </Card.Header>
        <Card.Body>
          <Table headers={headers} rows={rows} showControls={false} />
        </Card.Body>
      </Card>
    </>
  );
};
export default UserPage;
