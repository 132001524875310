import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { completeLoading } from '@store/features/uiSlice';

export const Loading = () => {
  const token = useSelector((state) => state.auth.token);
  const history = useHistory();
  const dispatch = useDispatch();
  // if (!token) {
  //   dispatch(completeLoading(''));
  //   history.replace('/login');
  //   return null;
  // }

  return (
    <div>
      Loading...
    </div>
  );
}
