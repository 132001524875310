import React from 'react';
import { Button } from "./Button";
import { defaultIconStyles } from "./defaultButtonIconStyles";

export function EditButton({ iconStyles, ...props }) {
  return (
    <Button variant="light" className="p-1 d-inline-flex rounded-circle mr-2" {...props}>
      <span style={iconStyles || defaultIconStyles} className='material-icons md-18 text-info rounded-circle mx-auto'>edit</span>
    </Button>
  )
}