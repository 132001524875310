import React, { useState } from 'react';
import { Modal as RBModal } from 'react-bootstrap';
import { Button } from '@components/util/buttons';

import AlertList from '@components/util/AlertList';

/**
 * @param {['xs' | 'sm' | 'md' | 'lg' | xl]} size
 * @param String title
 * @param Function renderBody
 * @param Function renderFooter default = undefined
 * @param Function onHide action to do after cancel/close, before the modal is hidden
 * @param Function onSubmit action to do after submit/save before the modal is hidden
 * @param Boolean show Controls whether the modal is visible
 * @param Boolean disabled Controls whether the submit button is disabled
 * @
 *
 * Renders a Modal.
 *
 * Use renderBody to pass modal body content.
 *
 * User renderFooter || undefined for footer content.
 *
 * onHide is called every time the modal is hidden - by Cancel / Submit / X buttons or clicking outside.
 * Use onHide / onSubmit as modal callbacks for Cancel/Save buttons.
 */
export default function Modal({
  size,
  title,
  renderBody,
  renderFooter,
  renderFooterLeftSide,
  onHide,
  onSubmit,
  show,
  submitIcon,
  submitText,
  closeText,
  closeButtonLoading = false,
  disabled
}) {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);

  const logError = error => {
    console.error(error);
    const newAlerts = error.userFriendlyMessages ?
      error.userFriendlyMessages.map((m, index) => ({id: error.id + index, message: m})) :
      [{ message: error.message }];

    setAlerts([...alerts, ...newAlerts]);
  };

  const handleHide = async () => {
    try {
      setAlerts([]);
      await onHide();
    } catch (error) {
      logError(error);
    }
  };

  const handleSubmit = async (e) => {
    setAlerts([]);
    setLoading(true);
    e.preventDefault();
    try {
      if (onSubmit) {
        await onSubmit();
        await handleHide();
      }
    } catch (error) {
      logError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <RBModal
        size={size}
        show={show}
        onHide={handleHide}
      >
        {title && <RBModal.Header className={`modal-header-custom-${size}`} >
          <RBModal.Title>
            { title }
            </RBModal.Title>
        </RBModal.Header>}
        <RBModal.Body>
          {!!alerts.length &&
            <div className='mb-3'>
              <AlertList alertClassName='w-100 mw-100' alerts={alerts} onRemoveAlert={(id) => setAlerts(alerts.filter(alert => alert.id !== id))} />
            </div>
          }
          { renderBody() }
        </RBModal.Body>
        <RBModal.Footer>
          { renderFooterLeftSide && renderFooterLeftSide() }
          {
            renderFooter ?
              renderFooter() :
              <>
                <Button variant="secondary" loading={closeButtonLoading} onClick={handleHide}>{closeText || 'Cancel'}</Button>
                { onSubmit &&
                  <Button variant="primary" disabled={disabled} onClick={handleSubmit} loading={loading} icon={submitIcon || 'save'}>
                    {submitText || 'Save'}
                  </Button>
                }
              </>
          }

        </RBModal.Footer>
      </RBModal>
    </>
  )
}
