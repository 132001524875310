import React from 'react';
import { Button as RBButton, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export function Button({disabled, loading, icon, iconPosition, iconClassName, className = '', showLoadingText = true, tooltipText = null, iconStyles={}, ...props}) {
  let content = loading && showLoadingText ? 'Working...' : props.children;
  if (icon) {
    const iconElement = <span className="material-icons md-18">{loading ? 'pending' : icon}</span>;
    if (iconPosition === 'right') {
      content = <>{content} {iconElement}</>;
    } else if (iconPosition === undefined || iconPosition === 'left') {
      content = <>{iconElement} {content}</>;
    } else {
      throw new Error(`Unsupported iconPosition: ${iconPosition}.`);
    }
  }

  const button = () => {
    // Disabling a button does not work well if the "as" property is a link. Since its disabled anyway, we can just remove the "as" property.
    if (props.as === Link && disabled) {
      props.as = undefined
    }
    // Bootstrap and Ayming Design System buttons have different style names
    // So when you want to use style from design system, here's the mapping: Ayming style = Bootstrap style
    // primary = primary | default = outline-primary | text = text
    return <RBButton className={`${disabled ? 'cursor-disabled' : ''} ${className}`} {...props} disabled={disabled || loading}>{content}</RBButton>
  };

  const buttonWithTooltip = () => {
    return <OverlayTrigger
      placement='top'
      overlay={
        <Tooltip>{tooltipText}</Tooltip>
      }
    >
      { button() }
    </OverlayTrigger>
  };

  return (
    <>
      { (!!tooltipText && !loading) ? buttonWithTooltip() : button() }
    </>
  );
}
