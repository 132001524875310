import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { PersistGate } from 'redux-persist/integration/react';
import './style.scss';
import App from './App';
import store, { persistor } from './store';
import { AppCache } from './utils/AppCache';

// clear the app cache on page reload
// AppCache.clear()

if (window.location.protocol !== 'https:' && !window.location.host.includes('localhost') && !window.location.host.includes('express')) {
  window.location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT,
  release: process.env.REACT_APP_RELEASE,
  ignoreErrors: ['ReferenceError']
});

ReactDOM.render(
  <Provider store={store}>
     <PersistGate loading={null} persistor={persistor}>
       <ProSidebarProvider>
        <div id='sidebar-container' />
        <App />
      </ProSidebarProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
