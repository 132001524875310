import React from 'react';
import Modal from '@components/util/Modal';
const SUPPORT_EMAIL_ADDRESS = 'support@help.com';

const HelpModal = ({show, onHide}) => {
  const closeText = 'Cancel'; 

  const modalBody = () => {
    return (
      <p>For help contact to: {SUPPORT_EMAIL_ADDRESS}</p>
    )
  }
  return (
    <Modal
      show={show}
      size="lg"
      closeText={closeText}
      title="help"
      renderBody={() => modalBody()}
      onHide={onHide}
    />
  );
}

export default HelpModal
