import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { handleError } from '../store/features/alertSlice';

export function useErrorHandling(callback, onFinally = null) {
  const dispatch = useDispatch();
  const onFinallyCallback = useMemo(() => onFinally || (() => {}), [onFinally]);

  const errorHandler = useCallback(
    async (...callbackParams) => {
      try {
        return await callback(...callbackParams);
      } catch (error) {
        dispatch(handleError(error));
      } finally {
        onFinallyCallback();
      }
    },
    [callback, dispatch, onFinallyCallback]
  );
  return errorHandler;
}
