import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from '@components/util/buttons';
import { Sidebar as PSidebar, Menu, SubMenu, MenuItem, useProSidebar } from 'react-pro-sidebar';

/**
 * @param {Array} headerItem - { text: String, link: String }
 * @param {Array} menuItems - { text: String, highlighted: boolean, link: String, subMenu: Array }
 * @param Boolean shouldReload - if true, will reload page on link click
 * @
 *
 * React Portal with sidebar component. Displays list of links and a header item which is usually a link to previous page
 *
 */
const SidebarPortal = ({ headerItem, menuItems, shouldReload, title, title2, menuItems2 }) => {
  const { collapseSidebar, collapsed } = useProSidebar();
  const user = useSelector((state) => state.auth.user);
  const container = document.getElementById('sidebar-container');
  const contentWrapper = document.getElementById('content-wrapper');

  const forceUpdate = (link) => {
    if (shouldReload) {
      window.location.assign(window.location.origin + link);
    }
  }

  const handleSidebarCollapse = () => {
    collapseSidebar();
    collapsed ? contentWrapper.style.left = "240px" : contentWrapper.style.left = "60px";
  }

  const renderMenu = items => {
    return items.map((item, index) => {
      if (item.subMenu) {
        return (
          <SubMenu key={index} label={item.text} defaultOpen={item.subMenu.some(subMenuItem => subMenuItem.highlighted)} >
            {item.subMenu.map((subItem, subIndex) => {
              return (
                <MenuItem 
                  key={subIndex} 
                  component={
                    <Link 
                      onClick={() => forceUpdate(`/${user.account_type}/${subItem.link}`)} 
                      to={`/${user.account_type}/${subItem.link}`}
                      title={subItem.text}
                    />
                  }
                >
                  {subItem.highlighted ? <b>{subItem.text}</b> : subItem.text}
                </MenuItem>
              )
            })}
          </SubMenu>
        )
      }
      return (
        <MenuItem 
          key={index} 
          component={
            <Link 
              onClick={() => forceUpdate(`/${user.account_type}/${item.link}`)} 
              to={`/${user.account_type}/${item.link}`}
              title={item.text}
            />
          }
        >
          {item.highlighted ? <b>{item.text}</b> : item.text}
        </MenuItem>
      )
    }
    )
  }
  useEffect(() => {
    if (contentWrapper) {
      contentWrapper.style.left = "240px";
    }

    return () => {
      if (contentWrapper) {
        contentWrapper.style.left = '0px';
      }
    }
  }, [contentWrapper]);
  
  const SidebarComponent = (
    <PSidebar
      backgroundColor="#ffffff"
      width='240px'
      collapsedWidth="60px"
      rootStyles={{
        position: 'fixed',
        height: '100%',
        fontSize: '1rem',
      }}
    >
      <Menu rootStyles={{
        background: '#0078B9 linear-gradient(180deg, #268cc4, #0078B9) repeat-x !important',
        height: '128px',
        paddingTop: '70px',
        color: '#ffffff',
      }}>
        {headerItem && 
          <MenuItem 
            component={<Link to={`/${user.account_type}/${headerItem.link}`} title={headerItem.text} />}
          >
            <span className="material-icons md-18 mr-2">arrow_back</span>
            {headerItem.text}
          </MenuItem>
        }
      </Menu>
      <Menu rootStyles={{
        color: '#0078B9',
      }}>
        {title && <p style={{paddingLeft: '10px'}}>{title}</p>}
        {menuItems && renderMenu(menuItems)}
        {title2 && <p style={{padding: '30px 0 0 10px'}}>{title2}</p>}
        {menuItems2 && renderMenu(menuItems2)}
      </Menu>
      <Menu rootStyles={{
        position: 'absolute',
        bottom: '1em'
      }}>
        <MenuItem>
          <Button onClick={() => handleSidebarCollapse()} variant='outline-primary' icon={collapsed ? 'arrow_right' : 'arrow_left'} />
        </MenuItem>
      </Menu>
    </PSidebar>
  );
  const portal = container ? ReactDOM.createPortal(SidebarComponent, container) : '';

  return (
    <>{portal}</>
  )  
}

export default SidebarPortal;
