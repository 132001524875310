import { createSlice } from '@reduxjs/toolkit';
import { AppCache } from '@utils/AppCache';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state, action) => {
      state.token = action.payload.data;
    },
    logout: (state) => { 
      state.token = null;
      AppCache.clear();
    },
    getCurrentSession: (state, action) => {
      state.user = action.payload.data;
    }
  }
});

export const { login, logout, getCurrentSession } = authSlice.actions;
export const selectUser = state => state.user;
export default authSlice.reducer;
