import React from 'react';
import ReactDOM from 'react-dom';

const BreadcrumbsPortal = ({ children }) => {
  const navbar = document.getElementById('navbar-breadcrumbs');
  const portal = navbar ? ReactDOM.createPortal(children, navbar) : '';
  
  return (
    <>{portal}</>
  )  
}

export default BreadcrumbsPortal;